import React from "react";

import AdminLayout from "../../components/AdminLayout";
export default function Dashboard() {
  return (
    <AdminLayout>
      <div>Welcome to the dashboard</div>
    </AdminLayout>
  );
}
