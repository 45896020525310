import React from "react";

import AdminLayout from "../../components/AdminLayout";
export default function Jobs() {
  return (
    <AdminLayout>
      <div className="jobs-page">
        <div className="section-1">
          <button className="btn">Add a job</button>
        </div>
        <div className="section-2"></div>
      </div>
    </AdminLayout>
  );
}
