import React from "react";
import logo from "../../img/ooon.png";
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
  FaPinterestP,
  FaTiktok,
} from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosMail, IoIosPhonePortrait } from "react-icons/io";
export default function Footer() {
  return (
    <div className="footer">
      <div className="container footercontainer">
        <div className="col1">
          <img src={logo} />
          <div className="col1txt">
            WE ARE THE FIRST AND LARGEST INTERNSHIP PLATFORM IN THE MENA REGION
          </div>
          <div className="col1links">
            <div>
              <FaFacebookSquare />
            </div>
            <div>
              <FaTwitter />
            </div>
            <div>
              <FaInstagram />
            </div>
            <div>
              <FaWhatsapp />
            </div>
            <div>
              <FaTiktok />
            </div>
            <div>
              <FaPinterestP />
            </div>
            <div>
              <FaYoutube />
            </div>
          </div>
        </div>

        <div className="col2">
          <div className="col2txt">
            LOREM IPSUM DOLOR SIT AMET, CONSECTETUER ADIPISCING ELIT, SED DIAM
            NONUMMY NIBH EUISMOD TINCIDUNT UT LAOREET DOLORE MAGNA ALIQUAM ERAT
            VOLUTPAT. UT WISI ENIM AD MINIM VENIAM, QUIS NOSTRUD EXERCI TATION
          </div>
        </div>
        <div className="col3">
          <h3>GET IN TOUCH</h3>
          <div className="col3links">
            <div>
              <FaMapMarkerAlt className="footer-icon" />
              20 AHMED AL SHATOURI, AD DOQI, DOKKI, GIZA GOVERNORATE
            </div>
            <div>
              <IoIosMail className="footer-icon" />
              INFO@PLUSCREATIVESTUDIO.COM
            </div>
            <div>
              <IoIosPhonePortrait className="footer-icon" />
              +20 1118887031
            </div>
          </div>
        </div>
      </div>
      <div className="cad">
        Copyright © 2021 Uoon | Powered by Creative Way AD
      </div>
    </div>
  );
}
