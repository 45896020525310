import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../img/ooon.png";
export default function Sidebar() {
  return (
    <nav className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} />
      </div>
      <div className="sidebar-content">
        <Link to="/admin/dashboard/jobs">
          <button className="btn sidebar-button">Jobs</button>
        </Link>
        <Link to="/admin/dashboard/users">
          <button className="btn sidebar-button">Users</button>
        </Link>
      </div>
    </nav>
  );
}
