import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppContextProvider } from "./AppContext";
import "./sass/main.scss";

import axios from "axios";

axios.defaults.baseURL = "http://localhost:5050";
axios.defaults.headers["authorization"] =
  "Bearer " + localStorage.getItem("token");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>
);
