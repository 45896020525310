import React from "react";
import Sidebar from "./mainsections/admin/Sidebar";
export default function AdminLayout(props) {
  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="admin-main-content">{props.children}</div>
    </div>
  );
}
