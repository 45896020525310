import React from "react";
import Navbar from "./mainsections/Navbar";
import Footer from "./mainsections/Footer";
export default function MainLayout(props) {
  return (
    <div>
      {props.navbar && <Navbar />}
      {props.children}
      {props.footer && <Footer />}
    </div>
  );
}
