import React from "react";
import logo from "../img/ooon.png"
import MainLayout from "../components/MainLayout";
export default function ComingSoon() {
  return (
    <MainLayout navbar footer>
      <div className="comingsoon-page">
        <img src={logo}/>
        <h1>Coming Soon!!!</h1>
      </div>
    </MainLayout>
  );
}
