import React, { useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const AppContext = React.createContext();

export const AppContextProvider = (props) => {
  const [state, setState] = React.useState({
    isLoggedIn: localStorage.getItem("token") ? true : false,
    token: localStorage.getItem("token"),
    userdata: localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : {},
  });

  const login = (token) => {
    localStorage.setItem("token", token);
    setState({
      isLoggedIn: true,
      userdata: {...jwt_decode(token)[0]},
    });
    
    axios.defaults.headers["authorization"] = "Bearer " + token;
  };

  const logout = () => {
    localStorage.removeItem("token");
    setState({
      isLoggedIn: false,
      token: null,
    });
    axios.defaults.headers["authorization"] = "Bearer " + null;
  };
  return (
    <AppContext.Provider
      value={{
        state,
        login,
        logout,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
