import React from "react";

export default function OpportunitieCard(props) {
  return (
    <div className="opportunitiecard">
      <div className="content">
        <h3>{props.title}</h3>
        <div>{props.body}</div>
      </div>
      <button className="btn">apply</button>
    </div>
  );
}
