import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";

import { AppContext } from "../AppContext";
import img1 from "../img/opp1.jpg";
import OpportunitieCard from "../components/OpportunitieCard";

import woman from "../img/woman.png";

export default function Opportunitie() {
  const { state } = useContext(AppContext);

  return (
    <MainLayout navbar footer>
      {state.userdata?.role !== "User" && <UnSignTrainee />}
      {state.userdata?.role === "User" && <SignTrainee />}
    </MainLayout>
  );
}
/*
function UnSignTrainee() {
  return (
    <div className="UnSign-sections-container">
      <div className="section-1">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          effect={"fade"}
          modules={[EffectFade, Autoplay]}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          <SwiperSlide>
            <div className="section section-1-1">
              <h2>Need to find new opportunities?</h2>
              <p>Use our engine for your next job</p>
              <div className="btns-container">
                <Link to="/signin">
                  <button className="btn signinbtn">Sign in</button>
                </Link>

                <Link to="/signup">
                  <button className="btn signupbtn">Sign Up</button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="section section-1-2">
              <h2>Search over 1000+ offer</h2>
              <p>Lorem</p>
              <div className="btns-container">
                <Link to="/signin">
                  <button className="btn signinbtn">Sign in</button>
                </Link>

                <Link to="/signup">
                  <button className="btn signupbtn">Sign Up</button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
*/
function UnSignTrainee() {
  return (
    <>
      <section className="section1">
        <div className="container">
          <div className="section1-background"></div>
          <div className="section1-content">
            <div className="section1-txt">
              Search <div className="fade">For</div> opportunities
            </div>
            <img className="section1-image" src={woman} />

            <form className="search">
              <input
                type="text"
                className="searchbar"
                placeholder="Search About Opportunities..."
              ></input>
              <button className="whtebtn"> Search</button>
            </form>
          </div>
        </div>
      </section>
      <div className="container">
        <OpportunitieCard
          title="Job Title #1"
          body="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
        />
        <OpportunitieCard
          title="Job Title #2"
          body="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled"
        />
        <OpportunitieCard
          title="Job Title #3"
          body="lorem lorem lorem lorem lorem lorem"
        />
      </div>
    </>
  );
}

function SignTrainee() {
  return (
    <div className="Signed-sections-container">
      <div className="container section-1">
        <h2>Recent opportunities</h2>
        <div className="section-1-content">
          <div>op1</div>
          <div>op2</div>
          <div>op3</div>
          <div>op4</div>
          <div>op5</div>
        </div>
      </div>
    </div>
  );
}
