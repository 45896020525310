import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AppContext } from "./AppContext";

import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/admin/Dashboard";
import Jobs from "./pages/admin/Jobs";
import Users from "./pages/admin/Users";
import Opportunitie from "./pages/Opportunitie";
import ComingSoon from "./pages/ComingSoon";

function App() {
  const { state } = useContext(AppContext);

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/opportunitie" exact element={<Opportunitie />} />
          <Route path="/Education" exact element={<ComingSoon />} />
          <Route path="/Community" exact element={<ComingSoon />} />

          {!state.isLoggedIn && (
            <>
              <Route path="/signin" exact element={<Login />} />
              <Route path="/signup" exact element={<SignUp />} />
            </>
          )}
          {state.userdata?.role == "Admin" && (
            <Route path="/admin/dashboard" exact element={<Dashboard />} />
          )}
          {state.userdata?.role == "Admin" && (
            <Route
              path="/admin/dashboard/jobs"
              exact
              element={<Jobs />}
            />
          )}
          {state.userdata?.role == "Admin" && (
            <Route path="/admin/dashboard/users" exact element={<Users />} />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
